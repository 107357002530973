<template>
  <div class="page-classroom-edit">
    <div class="my-page">
      <van-form @submit="save" style="background-color: transparent;">
        <div class="my-card">
          <van-field
            readonly
            required
            v-model="viewData.ChildrenName"
            name="ChildrenName"
            label="请假宝贝"
            placeholder="请假宝贝"
            :rules="[{ required: true, message: '请填写请假宝贝' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.ChildrenLeaveTypeName"
            name="ChildrenLeaveTypeName"
            label="请假类型"
            placeholder="请假类型"
            :rules="[{ required: true, message: '请选择请假类型' }]"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              value-key="EnumName"
              :columns="leaveTypeList"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.BeginDate"
            name="BeginDate"
            label="请假开始时间"
            placeholder="请假开始时间"
            :rules="[{ required: true, message: '请选择请假开始时间' }]"
          />
          <van-popup v-model="showTime" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              :min-date="minDate"
              @confirm="onConfirmTime"
              @cancel="showTime = false"
            />
          </van-popup>
          <van-field
            readonly
            required
            v-model="viewData.EndDate"
            name="EndDate"
            label="请假结束时间"
            placeholder="请假结束时间"
            :rules="[{ required: true, message: '请选择请假结束时间' }]"
          />
          <van-popup v-model="showTime2" position="bottom">
            <van-datetime-picker
              type="datetime"
              title="请选择日期"
              :min-date="minDate"
              @confirm="onConfirmTime2"
              @cancel="showTime2 = false"
            />
          </van-popup>
          <van-field
            required
            readonly
            type="number"
            v-model="viewData.LeaveDays"
            name="LeaveDays"
            label="请假天数"
            placeholder="请假天数"
            :rules="[
              { required: true, message: '请填写请假天数' },
              {
                pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数'
              }
            ]"
          />
          <van-field
            required
            readonly
            type="number"
            v-model="viewData.LeaveTimeCount"
            name="LeaveTimeCount"
            label="请假总时长"
            placeholder="请假总时长"
            :rules="[
              { required: true, message: '请填写请假总时长' },
              {
                pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,
                message: '请填写正数'
              }
            ]"
          />
          <van-field
            required
            readonly
            v-model="viewData.LeaveDesc"
            name="LeaveDesc"
            rows="2"
            label="请假事由"
            type="textarea"
            placeholder="请输入请假事由"
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field
            readonly
            required
            v-model="viewData.AuditUserIDListName"
            name="weituo"
            label="审核人"
            placeholder="审核人"
            :rules="[{ required: true, message: '请选择审核人' }]"
          />
          <van-field
            readonly
            v-model="viewData.AuditStateName"
            name="AuditStateName"
            label="审核状态"
            placeholder="审核状态"
            :rules="[{ required: true, message: '请选择审核状态' }]"
          />
          <van-popup v-model="showPicker2" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="pepoleList1"
              @confirm="onConfirm2"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            readonly
            v-model="viewData.CopyUserIDListName"
            name="weituo"
            label="抄送老师"
            placeholder="抄送老师"
            :rules="[{ required: false, message: '请选择抄送老师' }]"
          >
            <!-- <template #button>
              <van-icon size="22px" name="close" @click.stop="clearPeople2" />
            </template> -->
          </van-field>
          <van-popup v-model="showPicker3" position="bottom">
            <van-picker
              show-toolbar
              value-key="Name"
              :columns="pepoleList2"
              @confirm="onConfirm3"
              @cancel="showPicker3 = false"
            />
          </van-popup>
          <van-field name="AccessoryPostList" label="附件">
            <template #input>
              <van-uploader
                readonly
                v-model="viewData.AccessoryPostList"
                :deletable="false"
                :preview-full-image="false"
                accept="*"
                :before-read="beforeRead"
                @click-preview="review"
              />
            </template>
          </van-field>
        </div>
        <!-- <van-button
          :loading="loading"
          block
          color="#ffe500"
          type="info"
          native-type="submit"
          >提交</van-button
        > -->
      </van-form>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Icon,
  Toast
} from 'vant'
export default {
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
    [Toast.name]: Toast
  },
  data () {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      userInfo: JSON.parse(window.localStorage.getItem('userInfo')),
      id: null,
      viewData: {
        AccessoryPostList: [],
        CopyUserIDListName: ''
      },
      leaveTypeList: [],
      showPicker: false,
      showPicker2: false,
      showTime: false,
      showTime2: false,
      showPicker3: false,
      loading: false,
      pepoleList1: [],
      pepoleList2: []
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
      this.getEnum()
      this.getPickList()
    }
  },
  methods: {
    getEnum () {
      this.$axios.get('/api/Base/GetEnum?type=ChildrenLeaveType').then(res => {
        if (res.code === 200) {
          this.leaveTypeList = res.data.List
        }
      })
    },
    getPickList () {
      this.$axios
        .get('/api/Class/GetByClass', {
          classID: this.userInfo.OpenChildren.ClassID
        })
        .then(res => {
          if (res.code === 200) {
            // 1 委托，2抄送人
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].LeaveForChildrenType === 1) {
                this.pepoleList1.push(res.data[i])
              } else if (res.data[i].LeaveForChildrenType === 2) {
                this.pepoleList2.push(res.data[i])
              }
            }
          }
        })
    },
    getDetail () {
      this.$axios
        .get('/api/LeaveForChildren/Get', {
          ID: this.id
        })
        .then(res => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data,
              AuditUserIDListName: res.data.AuditUserName,
              CopyUserIDListName: res.data.CopyUserName,
              AccessoryPostList: res.data.accessoryList
            }
          } else {
            this.$toast.fail(res.msg || '请求异常')
            return false
          }
        })
    },
    onConfirm (value) {
      this.viewData.ChildrenLeaveTypeName = value.EnumName
      this.viewData.ChildrenLeaveType = value.EnumValue
      this.showPicker = false
    },
    onConfirm2 (value) {
      this.viewData.AuditUserIDList = [value.ID]
      this.viewData.AuditUserIDListName = value.Name
      this.showPicker2 = false
    },
    onConfirm3 (value) {
      const arrName = this.viewData.CopyUserIDListName
        ? this.viewData.CopyUserIDListName.split(',')
        : []
      const arrID = this.viewData.CopyUserIDList
        ? this.viewData.CopyUserIDList
        : []
      if (arrName.indexOf(value.Name) === -1) {
        arrName.push(value.Name)
        arrID.push(value.ID)
      } else {
        arrID.splice(arrName.indexOf(value.Name), 1)
        arrName.splice(arrName.indexOf(value.Name), 1)
      }
      this.viewData.CopyUserIDListName = arrName.join(',')
      this.viewData.CopyUserIDList = arrID
      console.log(arrName)
      console.log(arrID)
      // if (this.viewData.CopyUserIDListName) {
      //   this.viewData.CopyUserIDListName =
      //     this.viewData.CopyUserIDListName + ',' + value.Name
      // } else {
      //   this.viewData.CopyUserIDListName = value.Name
      // }
      this.showPicker3 = false
    },
    onConfirmTime (value) {
      this.viewData.BeginDate = new Date(value).format('yyyy-MM-dd hh:mm')
      this.showTime = false
      // 计算总时长
      this.sumTime()
    },
    onConfirmTime2 (value) {
      this.viewData.EndDate = new Date(value).format('yyyy-MM-dd hh:mm')
      this.showTime2 = false
      // 计算总时长
      this.sumTime()
    },
    sumTime () {
      const startTime = this.viewData.BeginDate
      const endTime = this.viewData.EndDate
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          'LeaveTimeCount',
          this.$moment(endTime)
            .diff(this.$moment(startTime), 'hours', true)
            .toFixed(1)
        )
      }
    },
    beforeRead (file) {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      let formData = new FormData()
      formData.append('LastFile', file)
      formData.append('FileName', file.name)
      this.$axios.upload('/api/File/UploadFile', formData).then(res => {
        Toast.clear()
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
            url: res.data.url
          })
          return true
        } else {
          this.$toast.fail(res.msg || '操作失败')
          return false
        }
      })
    },
    review (file) {
      if (file.url) {
        window.open(file.url)
      }
    },
    save () {
      let that = this
      this.$axios
        .post(
          this.id ? '/api/LeaveForChildren/Edit' : '/api/LeaveForChildren/Add',
          { ...this.viewData, ID: this.id }
        )
        .then(res => {
          if (res.code === 200) {
            this.$toast.success(res.msg || '操作成功')
            setTimeout(() => {
              that.$router.replace({
                path: 'LeaveBoby',
                query: {
                  active: 0
                }
              })
            }, 1000)
          } else {
            this.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    clearPeople2 () {
      // this.viewData.CopyUserIDListName = ''
      this.$set(this.viewData, 'CopyUserIDListName', '')
      this.$set(this.viewData, 'CopyUserIDList', [])
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
